<template>
    <div class="q-pa-md home">

        <div class="row justify-center">

            <QQButton label="Indietro"
                color="primary"
                icon="undo"
                size="xl"
                @click.native="$router.go(-1)"
            />

            <QQButton label="Persone e Famiglia"
                color="blue-grey"
                icon="supervisor_account"
                size="xl"
                v-if="isPersoneEnabled()"
                @click.native="onSelezionaArea('persona')" />

            <QQButton label="Professionisti"
                color="blue-grey"
                icon="work_outline"
                size="xl"
                v-if="isProfessionistiEnabled()"
                @click.native="onSelezionaArea('professionisti')"
            />

            <QQButton label="Aziende"
                color="blue-grey"
                icon="contact_mail"
                size="xl"
                v-if="isAziendeEnabled()"
                @click.native="inCostruzione" />

            <QQButton label="Cauzioni"
                color="blue-grey"
                icon="weekend"
                size="xl"
                v-if="isCauzioniEnabled()"
                @click.native="inCostruzione" />

            <QQButton label="Altri rami"
                color="blue-grey"
                icon="mdi-animation-outline"
                size="xl"
                v-if="isAltriRamiEnabled()"
                @click.native="inCostruzione" />

            <QQButton label="Retail"
                color="blue-grey"
                icon="mdi-book-open"
                size="xl"
                v-if="isRetailEnabled()"
                @click.native="inCostruzione" />

        </div>

    </div>
</template>

<script>

import QQButton from "@/components/QQButton.vue";
import policy from "@/libs/policy.js";
import { mapFields } from 'vuex-map-fields'
//import commonLib from "@/libs/commonLib.js";

export default {
    name: "SelezioneArea",
    data() {
        return {
            //jwt: commonLib.JWTtoJSon(this.$store.state.jwt)
        }
    },
    components: {
        QQButton
    },
    methods: {
        onSelezionaArea(area) {
            this.step = 1;
            this.$store.commit("setArea",area);
            //this.$router.push({name :"Preventivi.FormPreventivo?area="+area});
            this.$router.push({"path" :"FormPreventivo?area="+area});
        },
        isAutoEnabled() {
            return policy.isAutoEnabled();
        },
        isProfessionistiEnabled() {
            return policy.isProfessionistiEnabled();
        },
        isPersoneEnabled() {
            return policy.isPersoneEnabled();
        },
        isAziendeEnabled() {
            return policy.isAziendeEnabled();
        },
        isCauzioniEnabled() {
            return policy.isCauzioniEnabled();
        },
        isAltriRamiEnabled() {
            return policy.isAltriRamiEnabled();
        },
        isRetailEnabled() {
            return policy.isRetailEnabled();
        },
        isNatantiEnabled() {
            return policy.isCorpiNatantiEnabled();
        },
        inCostruzione() {
            alert("IN COSTRUZIONE");
        }
    },
    computed: {
        ...mapFields('formPreventivo', [
            "step"
        ])
    },
    async mounted() {
    }
}
</script>
